body.page-theater {
  .main-aside {
    //@include master-margin-t();
    //@include master-padding-t();

    display: flex;
    .main {
      width: 100%;
      .address {
        display: inline-flex;
        align-items: center;
        svg {
          width: 11px;
          margin-right: 10px;
        }
      }
      .theater-access {
        @include master-margin-t();
        border-radius: 9px;
        padding:0;
        .capper, .capper-padded {
          padding:0;
          width: 100%;
        }
        .lr {
          padding:40px;
          @include calc(width, '100% - 80px');
        }
      }
      .copy {
        margin:20px 0;
      }
      .line {
        padding:0;
        margin:40px 0;
      }
    }

    aside {
      @include widthMaxMin(350px);
      margin-left: 100px;
      img {
        width:150px;
      }
      .show {
        margin:0 0 20px 0;
        $tw: 270px;
        .copy {
          &.with-asset {
            margin-top: 10px;
          }
          p {
            margin-bottom: 0;
            line-height: 1.3em;
          }
        }
        img, .btn-outer {
          width:270px;
        }
        .btn-outer {
          margin-top: 10px;
          .btn {
            width: 100%;
          }
        }
        img {
          border-radius: 15px;
        }
        .copymain {
          margin-top: 10px;
        }
        .icon-copy {

          .icon {
            top:1px;
          }
          .copy {
            .title-link {
              font-size: 18px;
            }
          }
        }

      }
      .line {
        padding:0;
        margin:40px 0;
      }
      .link {
        margin:20px 0 0 0;
      }

      .theatre-small-photo {
        margin-bottom: 25px;
        img {
          width: 100%;
          max-width: 270px;
        }
      }
      .current-show {
        margin-bottom: 0;
      }
    }

    @media only screen and (max-width: $breakpoint-small-medium) {
      display: block;
      .main {
          width: 100%;
      }
      .col {
        @include widthMaxMin(100%);
        margin-left: 0;
        margin-top: 40px;
      }
    }
  }
}