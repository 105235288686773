#mediaOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  visibility: hidden;

  #mediaOverlayScrim {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: block;
  }

  #mediaOverlayInner,
  #mediaOverlayInner .inner2,
  #swiper-super-outer,
  .swiper,
  .swiper-wrapper,
  .swiper-slide,
  iframe {
    width: 100%;
    height: 100%;
    position: relative;
  }
  iframe {
    border: 0;
  }

  .caption {
    position: absolute;
    bottom:0;
    left:0;
    @include calc(width,'100% - 20px');
    background-color: rgba(25, 25, 25, 0.8);

    padding:10px;
    color:#B5B5B5;
    @include font-montserrat-medium();
    * {
      color:#B5B5B5;
      @include font-montserrat-medium();
    }
  }

  .side {
    position: absolute;
    top:calc(50% - 26px);
    &.prev {
      right: unset;
      left:-10px;
      @media only screen and (max-width: $breakpoint-small) {
        left:60px;
      }
    }
    &.next {
      left: unset;
      right:-10px;
      @media only screen and (max-width: $breakpoint-small) {
        right:60px;
      }

    }
  }

  #mediaOverlayInner .inner2 {
    @include calc(width, '100% - 200px');
    @include calc(height, '100% - 100px');
    margin: 50px 100px;
    @media only screen and (max-width: $breakpoint-small) {
      @include calc(width, '100% - 40px');
      @include calc(height, '100% - 40px');
      margin: 20px;
    }

    .bgimg {
      background-size: contain;
    }
  }


  #media-dots {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    @media only screen and (max-width: $breakpoint-small) {
      margin-top: 0;
    }
    .dots {
      .swiper-pagination-bullet  {
        background-color: rgba(255, 255, 255, 0.7);
        &.swiper-pagination-bullet-active {
          background-color: rgba(255, 255, 255, 1);
        }
      }
      &.swiper-pagination-disabled {
        display: none;
      }
    }
  } //quick-thumbs-dots


  .overlay-x {
    position: absolute;
    z-index: 20;
    right: 29px;
    top: 0px;
  }

}
