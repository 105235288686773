body.admin-api {
  * {

  }
  #content {
    padding:20px;
  }
  #api-header {
    background-color: $color-dark;
    .inner {
      padding:20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      img {
        width:200px;
      }
      span {
        color:#fff;
        margin-left: 40px;
        top:10px;
        position: relative;
        @include font-montserrat-semibold();
        font-size: 14px;
      }
    }
  }
  #api-breadcrumbs {
    padding:15px 0 0;
    ul {
      margin-bottom: 0;
      padding-bottom: 0;
      li {
        list-style: none;
        display: inline;
        margin-left: 10px;
        a {
          &.active {
            text-decoration: underline;
          }
        }
      }
    }
  }

  h1, p {
    margin: 0 0 20px 0;
  }
  table, tr, td, th {
    vertical-align: center;
  }
  td, th {
    padding:5px 10px;}
  th * {
    font-weight: bold;
  }
  .button {
    cursor: pointer;
  }
  #api-header {

  }
}
