$mobilenav_hamburger_off: #fff;
$mobilenav_hamburger_on: #fff;

$headerHeightSmall: 0;
#header-mobile,
#mobile-nav-overlay-outer,
#mobile-nav-hamburger,
.header-mobile-bar {
  display: none;
}

@media only screen and (max-width: $breakpoint-mobile-nav) {
  // hide desktop
  .header-strip, .header-logo, .header-shows {
    display: none;
  }
  .header-mobile-bar {
    position: -webkit-sticky;
    position: sticky;
    top:0;
    background-color: #000000;

    z-index: 100001;
    display: block;
    padding-top: 6px;
    padding-bottom: 16px;

    .lr {
      display: flex;
      width: 100%;

      .l {
        @include widthMaxMin(225px);

        img {
          width: 100%;
        }
      }

      .r {
        width: 100%;
      }
    }
  }
  #mobile-nav-overlay-outer {
    background-color: #000;
    display: block;
    //position: fixed;
    //top: $headerHeightSmall;
    //left:0;
    width: 100%;
    z-index: 1000;
    overflow: hidden;
    padding-bottom: 40px;
    margin-bottom: 40px;
    border-bottom: 2px solid #3E3E3E;

    &.hidden {
      border-bottom: none;
      display: none;
    }
  }
  #header-mobile {
    display: block;
  }
  .header-mobile {
    display: block;
    position: absolute;
    width: 70px;
    height: 50px;
    z-index: 5000;
    top: 12px;
    right: 5px;

  }
  #mobile-nav-inner {
    padding: 0;
  }
  #mobile-nav-hamburger {
    display: block;
    position: absolute;
    top: 8px;
    right: 0;
    z-index: 2;
  }
  .hamburger {
    padding: 5px;
  }
  .hamburger-inner,
  .hamburger-inner::after,
  .hamburger-inner::before {
    background-color: $mobilenav_hamburger_off;
    border-radius: 0;
  }
  .is-active .hamburger-inner,
  .is-active .hamburger-inner::after,
  .is-active .hamburger-inner::before {
    background-color: $mobilenav_hamburger_on !important;
  }

  #mobile-nav-overlay {
    //background-color: #000;
    //border-top: 4px solid $color-blue-dk;
    //border-bottom: 4px solid $color-blue-dk;
    @include master-padding-lr-width();
    position: relative;
    @include transition_all();
    display: block;

    &.hidden {
      display: none;
    }

    nav {
      padding: 20px;
    }

    @media only screen and (max-width: $breakpoint-mobile-nav) {
      .lang-switcher {
        display: none;
      }
    }


    nav ul {
      margin: 10px 0;

      li {
        list-style: none;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        .item-on {
          display: none;
        }

        &.action-li {
          //margin-top: 30px;
        }

        .action-hands {
          @media only screen and (max-width: $breakpoint-mobile-nav) {
            img {
              position: absolute;
              width: 38px;
              top: -20px;
              left: 120px;

              body.es-us & {
                left: 135px;
              }

              margin-right: 5px;
              vertical-align: text-bottom;
            }
          }
        }

        a {
          @include font-montserrat-medium();
          font-size: 16px;
          @include transition_all();
          //@include underlinehover-generic($color-red,'sideswiper');
          &:hover {
            //color:$color-red;
          }
        }

        &.li-level1 {
          border-bottom: 1px solid #fff;

          a {
            color: #fff;

            &.active {
              color: $color-red;
            }
          }

          &.search {
            border-bottom: 0 solid #fff;
          }
        }

        &.li-level2 {
          a {
            color: #fff;

            &.active {
              color: $color-red;
            }
          }

          //border-bottom: 1px solid #fff;
        }

        .hover-holder-level2 {
          padding: 0 20px;
        }

        #nav-search {
          margin-top: 30px;

          input {
            @include calc(width, '100% - 20px');
            padding: 5px 10px;
            @include font-montserrat-medium();
            font-size: 16px;
          }

          input.search-submit {
            background-color: transparent;
            position: absolute;
            top: 1px;
            right: 0;
            cursor: pointer;
            width: 15px;
          }

        }
      }

      //li
    }

    .mobile-search {
      margin: 20px 0 0;
    }

    .button {
      margin: 20px 0 0;

      .global-button {
        @include calc(width, '100% - 8px');
        text-align: center;
      }
    }

  }
  //mobile-nav-overlay


  .mobile-content {
    width: 100%;
    text-align: left;
    margin: 0 auto;

    .logline {
      margin: 20px 0;
    }

    .bars {
      display: flex;
      width: 100%;

      .bar {
        width: 50%;
        border-top: #707070 solid 1px;
        border-bottom: #707070 solid 1px;
        border-left: #707070 solid 1px;
        &.full {
          width: 100%;
        }

        &:first-child {
          border-radius: 5px 0 0 5px;
        }

        &:last-child {
          border-radius: 0 5px 5px 0;
          border-right: #707070 solid 1px;
        }

        .dropdown {
          .dropdown-inner {
            //padding: 10px 0;
            img {
              filter: invert(1);
            }

            .items li.li-level1-hook {
              padding: 10px 0;
            }

            .selected .triangle svg path {
              fill: #fff
            }
          }
        }
        &.bar-language, &.bar-search {
          .dropdown {
            .dropdown-inner {
              .selected {
                padding:15px;
              }
            }
          }
          svg {
            path {
              fill: #797979!important;
            }
          }
          .dropdown-search .fields-button {
            display: block;
          }
        }
        &.bar-language {
          .dropdown .dropdown-inner .items {
            left:0;
            right: unset;
            //@include calc(width,'100% - 20px');
            width: 100%;
          }
        }
        &.bar-search {
          .dropdown .dropdown-inner .items {
            left:0;
            right: unset;
            @include calc(width,'100% - 20px');
          }
        }
      }
    }

    .mobile-social {
      margin: 20px 0;
      text-align: center;
    }

    .dynamic-nav-bars {
      display: block;
      .bar {
        width: 100%;
        border: none;
        * {
          color: #fff;
          font-size: 14px;
        }
        .lbl {
          color: #fff!important;
        }
        svg {
          path {
            fill:#fff;
          }
        }
        .btn.square {
          width: 100%;
        }
      }

    }

    .dynamic-nav-bars,
    .nyc-touring {
      .bar {
        border-bottom: #707070 solid 1px;
        border-left: none!important;
        border-right: none!important;
        border-radius: 0;
        padding: 0;
        margin: 20px 0;
        &:first-child {

        }
      }
      .dropdown {
        margin: 20px 0;

        .dropdown-inner {
          * {
            color: #fff;
            font-size: 14px;
          }

          .selected {
            padding: 0;

            * {
              color: #fff;
              font-size: 14px;
            }
            .triangle svg path {
              fill: #fff;
            }
          }
        }

        &.open {
          .dropdown-inner {
            background-color: transparent;
          }
        }

        .items {
          width: 100%;
          padding: 0;
          position: relative;
          top: 0;
          border: none;
          background-color: transparent;
          margin-top: 10px;
          ul {
            display: block;

            li {
              a {
                padding: 10px 0;
                width: 100%;
              }
            }
          }
        }
      }
    }
    .nyc-touring {
      .bar {
        &:first-child {
          border-top: #707070 solid 1px!important;
        }

      }
      .dropdown .dropdown-inner .selected {
        * {
          color: $color-gray;
        }
        .triangle svg path {
          fill: $color-gray;
        }
      }
    }
    .dynamic-nav-bars  {
      .bar {
        &:last-child {
          border: none!important;
        }
      }
    }
    .social-icons {
      .icon svg {
        path {
          fill: #fff;
        }
      }
    }
  }
}
