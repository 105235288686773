body.page-theaters {
  .top {
    text-align: center;
    .copy {
      text-align: center;
      max-width: 620px;
      width: 100%;
      margin: 0 auto;
    }
  }
  .theater-holder {
    margin:100px 0;
    .theaters {
      @include master-margin-tb();
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 40px;
      row-gap: 50px;
      @media only screen and (max-width: $breakpoint-medium) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media only screen and (max-width: $breakpoint-small-medium) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media only screen and (max-width: $breakpoint-small) {
        grid-template-columns: repeat(1, 1fr);

        row-gap: 50px;
        .item {
          border-bottom: 1px solid #B5B5B5;
          padding-bottom: 40px;
        }
      }
      .icon-copy {
        &.calendar {
          .bold {
            font-size: 14px;
            display: block;
          }
          a {
            color:$color-pink;
            * {
              @include font-montserrat-semibold();
              color:$color-pink;
            }
            &:hover {
              color:$color-red;
              * {
                color:$color-red;
              }
            }
          }
        }
      }
    }
  }
}
