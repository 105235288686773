aside.aside {
  position: -webkit-sticky;
  position: sticky;
  top:70px;
  .aside-block {
    background-color: #EFEFEF;
    margin-bottom: 60px;
    @media only screen and (max-width: $breakpoint-small) {
      margin-bottom: 30px;
    }
    border-radius: 9px;
    .aside-block2 {
      padding:20px;
      a {
        h5 {
          color: $color-pink;
          @include transition-all();
        }
        &:hover {
          h5 {
            color: $color-red!important;
          }
        }
      }
      .organization-img {
        width: 100%;
      }
    }
    .subtitle {
      font-size: 18px;
      @include font-montserrat-semibold();
    }
    .icon-copy {
      display: flex;
      margin: 15px 0;
      .icon {
        @include widthMaxMin(12px);
        margin-right: 15px;
        img {
          width: 100%;
        }
      }
      .copy {
        .bold {
          @include font-montserrat-semibold();
        }
        .regular {
          @include font-montserrat-medium();
        }
      }
    }
    .row-line {
      width:100%;
      display: block;
      height: 1px;
      background-color: #CBCBCB;
    }
    .row-time {
      display: flex;
      justify-content: space-between;
      margin:20px 0;

      .l, .r {
        width:50%;
        @include font-montserrat-semibold();
        &.no {
          color:#B2B2B2;
        }
        text-align: left;
      }
    }
    .line {
      width:100%;
      height: 1px;
      margin:40px 0 ;
      display: block;
      background-color:#CBCBCB;
      padding:0;
    }
  }

  .cast-recording {
    .btn-outer {
      margin-bottom: 20px;
      .btn {
        width: 100%;
        svg {
          width: 12px;
          margin-right: 10px;
          path {
            fill: #fff;
          }
        }
        &.amazon {
          svg {
            width:14px;
          }
        }
        &.apple {
          svg {
            top:-1px;
          }
        }
        span {
          top:-3px;
          position: relative;
        }
      }

    }
  }

  &.aside-theatre {
    * {
      font-size: 16px;
    }
  }

  &.aside-generic {
    .aside-block {
      background-color: transparent;
    }
    .footer-sponsors {
      padding:0;
    }
    .prefooter .footer-promotions {
      padding:0;
    }
  }
}