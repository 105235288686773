body.page-search {
  .grid-holder.search-grid .grid .item .bgimg {
      background-color: $color-dark;
  }
  .flx-lr {
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: $breakpoint-small) {
      flex-wrap: wrap;
      flex-direction: column-reverse;
    }

    .l {
      width: 100%;
    }
    .r {
      @include widthMaxMin(400px);
      @media only screen and (max-width: $breakpoint-small) {
        @include widthMaxMin(100%);
        margin-bottom: 20px;
      }
      .fields-button .fields .field input {
        background-color: #F5F5F5;
        @include set-input-placeholder-color(#797979);
      }
      .fields-button .fields .field,
      .dropdown-search .tabs .tab {
        @include font-montserrat-semibold();
        font-size: 14px;
      }
    }
  }
  .jumpto {
    .jump-line {
      color:$color-pink;
      @include font-montserrat-semibold();
      margin:0 10px;
      &:last-child {
        display: none;
      }
    }
  }
  .grid {
    .item .info h4 {
      color:$color-dark;
    }
  }
  .theatre-sec {
    h5 {
      span {
        @include font-montserrat-regular();
        color:#191919;
      }
    }
    .tour-linkout-row {
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
