body.page-home,
body.page-show,
body.page-tour {
  #content {
    padding-top: 0 !important;
    @media only screen and (min-width: $breakpoint-small-plus-1) {
    }
  }

  .synopsis-holder {
    @media only screen and (max-width: $breakpoint-small) {
      margin-bottom: 40px;
    }
  }

  .show-content {
    @include master-padding-tb();
    .lr {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      @media only screen and (max-width: $breakpoint-small) {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
      }
      .main {
        width: 100%;
        @media only screen and (max-width: $breakpoint-small) {
          margin-top: 60px;
        }
      }
      aside {
        @include widthMaxMin(400px);
        margin-left: 50px;
        @media only screen and (max-width: $breakpoint-small) {
          @include widthMaxMin(100%);
          margin-left: 0;
          position: relative;
          top:0;
        }
      }

      .main {
        .do-media-hook {
          &.without-media {
            display: none;
            @media only screen and (max-width: $breakpoint-small) {
              display: block;
            }
          }
        }
        .no-mobile {
          @media only screen and (max-width: $breakpoint-small) {
            display: none!important;
          }
        }

        .main-block {
          margin-bottom: 20px;
          padding-bottom: 30px;
          .btn-holder {
            margin-bottom: 50px;
          }
          .copy {
            margin-bottom: 40px;
          }
          h4 {
            display: inline-flex;
            align-items: center;
            .icon {
              width: 14px;
              margin-left: 10px;
            }
          }
          h2 {
            margin-bottom: 10px;
          }
          h6.special-cat {
            color:#797979;
            text-shadow: 0px 0px 6px rgba(0,0,0,0.16);
            text-transform: uppercase;
            font-size: 12px;
            @include font-montserrat-semibold();
            margin-bottom: 20px;
          }

          .ibdb-logo {
            display: flex;
            align-items: flex-end;
            .logo {
              margin-right: 10px;
              img {
                width:85px;
              }
            }
            .link {
              a {
                font-size: 11px;
                @include font-montserrat-semibold();
                color: $color-gray;
              }
            }

          }

        }
      }


    }

  }

  .footer-promotions {
    padding-top:0;
    .line {
      display: none;
    }
  }
}

body.page-cast-recordings {

}
