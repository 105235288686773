body.page-general {
  //background-color: $color-dark;
  #featured-gallery {
    z-index: 2;
  }

  .bottom {
    .bottom-bg {
      top:-100px;
      width: 100%;
      height: auto;
      aspect-ratio: 2;
    }
    .panel {
      @include master-margin-tb();
      width:100%;
      @include master-padding-tb();
      border-bottom: 1px solid #3E3E3E;
      &:last-child {
        border-bottom: none;
      }
      .lr {
        width:100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .l {
          @include widthMaxMin(180px)
        }
        .r {
          width:100%;
        }
      }
      h1 {
        white-space: pre-line;
        word-spacing: 180px;
      }
      h2 {
        @include font-montserrat-bold();
        font-size: 18px;
        color: $color-pink;
        text-transform: uppercase;
      }
      .btn-outer {
        margin-top: 20px;
      }
    }
  }

  .mini-col {
    margin: 0 auto;
    max-width: 600px;
    @media only screen and (max-width: $breakpoint-small) {
      width: 100%;
      max-width: unset;
    }
  }
  .header-copy-border-holder {
    @include master-margin-tb();
  }

  .perforamnce-times-table {
    .top-copy {
      @include master-margin-tb();
      * {
        //font-size: 13px;
      }
    }
    .bottom-copy {
      @include master-margin-tb();

    }
    .showtimes-table {
      @include master-margin-tb();
      a {
        text-decoration: none;
        @include font-montserrat-semibold();
      }
      .table-header {
        vertical-align: bottom;
      }
      table {
        width: 100%;
        tr {
          display: grid;
          width: 100%;
          grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
          @media only screen and (max-width: $breakpoint-small) {
            grid-template-columns: 2fr 1fr 1fr;
          }
        }

        .table-hook-desktop {
          display: block;
        }
        .table-hook-mobile {
          display: none;
        }
        .table-hook-all.special {
          display: block;
        }
        @media only screen and (max-width: $breakpoint-small) {
          .table-hook-desktop {
            display: none;
          }
          .table-hook-mobile {
            display: block;
          }
          .table-hook-all.special {
            display: none;
          }
        }

        th {
          align-items: end;
          .l2 {
            @include font-montserrat-regular();
          }
          .flx {
            display: flex;
            align-items: flex-end;
            width: 100%;
            height: 100%;
            flex-wrap: wrap;
            div {
              width: 100%;
            }
          }
        }
        td, th {
          //padding:5px 10px;
          padding:20px;
          display: flex;
          align-items: center;
          @media only screen and (max-width: $breakpoint-small-medium) {
            padding:15px;
          }
          @media only screen and (max-width: 1050px) {
            padding:10px;
          }
          @media only screen and (max-width: $breakpoint-small) {
            padding:15px;
          }
          @media only screen and (max-width: $breakpoint-smaller) {
            padding:10px;
          }
        }
        th {
          padding:5px 20px;
        }
        tr {
          td {
            border-top: 1px solid #B5B5B5;
            border-left: 1px solid #B5B5B5;

            &:last-child {
              border-right: 1px solid #B5B5B5;
            }
            &.special {
              @media only screen and (max-width: $breakpoint-small) {
                border-right: 1px solid #B5B5B5;
              }
            }
          }
          &:last-child {
            td {
              border-bottom: 1px solid #B5B5B5;
            }
          }
          td.special {
            @media only screen and (max-width: $breakpoint-small) {
              //border-bottom: 1px solid #B5B5B5;
            }
          }

          //curves
          &:nth-child(2) {
            margin-top: 10px;
            td:first-child {
              border-top-left-radius: 10px;
            }
            td:last-child {
              border-top-right-radius: 10px;
            }
            td.special {
              @media only screen and (max-width: $breakpoint-small) {
                border-top-right-radius: 10px;
              }
            }
          }
          &:last-child {
            td:first-child {
              border-bottom-left-radius: 10px;
            }

            td:last-child {
              border-bottom-right-radius: 10px;
            }
            td.special {
              @media only screen and (max-width: $breakpoint-small) {
                border-bottom-right-radius: 10px;
              }
            }

          }
        }
      }
      text-align: left;
    }
  }

  .organization-rows {
    margin-top: 20px;
    .organization-row {
      border-bottom: 1px solid #E0E0E0;
      padding-bottom: 20px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .col {
        padding:10px 10px 10px 0;
        .l1 {
          @include font-montserrat-medium();
        }
      }
      .col1 {
        width:75%;
      }
      .col2 {
        width:25%;
        svg {
          width:15px;
          height: 15px;
          margin-right: 15px;
        }
        .l1 {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }
      @media only screen and (max-width: $breakpoint-small) {
        display: block;
        .col1, .col2, .col3 {
          width: 100%;
        }
      }
    }

  }
}
