.featured-show-panel {
  background-image: url(../images/global/bg-show-featured.png);
  background-size: cover;
  .capper-padded {
    @media only screen and (max-width: $breakpoint-small) {
      padding: 0;
      width: 100%;
    }
  }
  .featured-show-panel2 {
    padding:60px 0 120px;
    @media only screen and (max-width: $breakpoint-small) {
      padding-top: 0;
      padding-bottom: 20px;
    }
  }
  .lr {
    display: flex;
    @media only screen and (max-width: $breakpoint-small) {
      flex-direction: column-reverse;
      flex-wrap: wrap;
    }
    .col1 {
      width: 33%;
      @media only screen and (max-width: $breakpoint-small) {
        width: 100%;
      }
    }
    .col2 {
      width: 67%;
      @media only screen and (max-width: $breakpoint-small) {
        width: 100%;
      }
    }

    .bar {
      .bar-col {
        @media only screen and (min-width: $breakpoint-small-plus-1) {
          min-height: 54px;
          a {
            min-height: 54px;
            button {
              min-height: 54px;
            }
          }
        }
      }
    }
    .poster {
      &.img {
        aspect-ratio: 1;
        background-size: cover;
        @media only screen and (min-width: $breakpoint-small-plus-1) {
          border-top-left-radius: 15px;
        }
        @media only screen and (max-width: $breakpoint-small) {
          width: 56%;
          margin: -23% auto 40px;
          z-index: 2;
        }
      }

      &.bar {
        @media only screen and (min-width: $breakpoint-small-plus-1) {
          border-bottom-left-radius: 15px;
        }
        background-color: #000;
        @media only screen and (max-width: $breakpoint-small) {
          background-color: transparent;
        }
        .social-icons {
          width:100%;
          text-align: center;
          justify-content: center;
          .icon {
            display: inline-block;

            a {
              @media only screen and (max-width: $breakpoint-small) {
                svg {
                  path {
                    fill: #fff;
                  }
                }
                &:hover {
                  svg {
                    path {
                      fill: $color-red;
                    }
                  }
                }
              }
            }
          }
        }//social

        //TOUR
        &.tours {
          height: 100%;
          .bar-col {
            width:100%;
            border-right: none;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            a, button {
              display: block;
              width: 100%;
              height: 100%;
              border-radius: 0;
              border-bottom-left-radius: 15px;
              @media only screen and (max-width: $breakpoint-small) {
                border-radius: 30px;

              }
            }
          }
          @media only screen and (max-width: $breakpoint-small) {
            width: 90%;
            margin: 0 auto;
          }
        }//tours

      }//bar
    }//poster


    .panel {
      &.img {
        aspect-ratio: 2;
        background-color: #000;
        @media only screen and (max-width: $breakpoint-small) {
          aspect-ratio: 1.54;
        }
        @media only screen and (min-width: $breakpoint-small-plus-1) {
          border-top-right-radius: 15px;
        }
        .bgimg {
          //background-size: contain;
          @media only screen and (min-width: $breakpoint-small-plus-1) {
            border-top-right-radius: 15px;
          }
        }
      }

      &.bar {
        display: flex;
        justify-content: space-between;
        @media only screen and (max-width: $breakpoint-small) {
          display: block;
          width: 90%;
          margin: 0 auto;
        }
        .bar-col {
          width: 51%;
          @media only screen and (max-width: $breakpoint-small) {
            width: 100%;
          }
          a, button {
            display: block;
            width: 100%;
            border-radius: 0;
            @media only screen and (max-width: $breakpoint-small) {
              border-radius: 30px;
            }
          }
          button {
            padding:20px 0;
            @media only screen and (max-width: $breakpoint-small) {
              margin-bottom: 20px;
            }
          }
          &:first-child {
            border-right: 1px solid #000;
            @media only screen and (max-width: $breakpoint-small) {
              border-right: none;
            }
          }
          &:last-child {
            button {
              @media only screen and (min-width: $breakpoint-small-plus-1) {
                border-bottom-right-radius: 15px;
              }
            }
          }
        }//bar-col

        &.tours {
          justify-content: right;
          background-color: #000;
          border-bottom-right-radius: 15px;
          .social-icons {
            margin-right: 15px;
          }
          @media only screen and (max-width: $breakpoint-small) {
            margin-bottom: 20px;
            margin-top: -15px;
            text-align: center;
          }
        }
      }//bar
    }//pael



    //////////////////////////////////////////////////////////////////////////////////////////////////
    &.without-media {
      .poster.img {
        border-radius: 0;
      }
      @media only screen and (max-width: $breakpoint-small) {
        .poster-outer {
          padding-top: 20px;
        }
        .poster.img {
          width: 100%;
          margin: 0 auto;
        }
      }
      .btns {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .btn-outer {
          margin:20px 20px 20px 0;
        }
        @media only screen and (max-width: $breakpoint-small) {
          display: none;
        }
      }
      .copy {
        @media only screen and (max-width: $breakpoint-small) {
          display: none;
        }
        .rte {
          @include calc(width, '100% - 80px');
        }
        h2 {
          margin-bottom: 5px;
        }
        h6 {
          color: $color-gray;
        }
        .description {
          margin-top: 10px;
        }
      }
      .social-icons {
        display: flex;
        justify-content: center;
        height: auto;
        width: 100%;
        margin:20px 0 0;
        .icon {
          a {
            svg {
              path {
                fill: #fff;
              }
            }
            &:hover {
              svg {
                path {
                  fill: $color-pink;
                }
              }
            }
          }
        }
      }
    }
  }

}

.show-tour-header {
  &.without-media {
    .lr {
     align-items: flex-end;
      margin-bottom: 40px;
    }
    @media only screen and (max-width: $breakpoint-small) {
      @include master-padding-lr-width();
    }
  }
}

.special-hook-mobile-tours-without-media {
  width: 100%;
  margin-top: 15px;
  a {
    display: block;
    width: 100%;
    button {
      width: 100%;
    }
  }
}