body.page-shows,
body.page-tours,
body.page-international,
body.page-cast-recordings {
  background-color: $color-dark;

  .bgscrim {
    aspect-ratio: 2.6;
    height: auto;
  }

  .top {
    width: 100%;

    .copy {
      color: #B5B5B5;
    }

    .lr {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      @media only screen and (max-width: $breakpoint-small-medium) {
        display: block;
      }

      .l {
        width: 100%;
      }

      .r {
        margin-left: 60px;
        @include widthMaxMin(650px);
        @media only screen and (max-width: $breakpoint-small-medium) {
          @include widthMaxMin(100%);
          margin-left: 0;
        }

        .filters-popup-hook-outer {
          text-align: right;

          @media only screen and (max-width: $breakpoint-small) {
            background-color: #000;
            padding:10px 10px 8px;
            border: 1px solid #535353;
            border-radius: 5px;
          }

          .filters-popup-hook {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            @media only screen and (max-width: $breakpoint-small) {
              justify-content: space-between;
            }

            @include font-montserrat-medium();
            color: #9D9D9D;
            font-size: 16px;
            cursor: pointer;
            @include transition-all();

            .txt {
              margin-right: 7px;
            }

            svg {
              width: 28px;
              height: auto;

              path {
                fill: #9D9D9D;
                @include transition-all();
              }
            }

            &:hover {
              color: #fff;

              svg {
                path {
                  fill: #fff;
                }
              }
            }
          }
        }

        .enhanced-filters {
          position: absolute;
          top: -10px;
          right: 0;
          z-index: 100;
          background-color: #000;
          border: 1px solid #535353;
          border-radius: 5px;
          width: calc(100% - 20px);
          max-width: 410px;

          .enhanced-filters2 {
            padding: 20px;

            .overlay-x {
              top: 15px;
              right: 20px;
            }

          }

          .btn {
            text-transform: unset;
          }

          .apply {
            width:100%;
            .btn {
              width:100%;
              padding:10px 0;
            }
            display: none;
            @media only screen and (max-width: $breakpoint-small) {
              display: block;
            }
          }

          .lbl {
            @include font-montserrat-semibold();
            color: #707070;
            font-size: 14px;
            margin-right: 20px;
            display: block;
            margin-bottom: 5px;
            @media only screen and (max-width: $breakpoint-small-medium) {
              width: 100%;
              display: block;
            }
          }

          .week-picker-header {
            .flx-lr {
              display: flex;
              justify-content: space-between;
              .week-picker-header-l {

              }
              .week-picker-header-r {
                text-align: right;
                span {
                  color: $color-pink;
                  @include transition-all();
                  cursor: pointer;
                  &:hover {
                    color: #fff;
                  }
                }
              }
            }

          }
          .week-picker {
            .ui-widget.ui-widget-content {
              width: 100%;
              border: none;
            }

            .ui-widget-header {
              background-color: transparent;
              @include font-montserrat-semibold();
              font-size: 19px;
              border: none;
              .ui-datepicker-prev,
              .ui-datepicker-next {
                cursor: pointer;
                top:10px;
              }
              .ui-state-hover {
                background-color: transparent;
                border: none;

              }
              .ui-icon {
                background-image: none;
                &.ui-icon-circle-triangle-w,
                &.ui-icon-circle-triangle-e {
                  overflow: visible;
                  &:before,
                  &:after {
                    content: '';
                    position: absolute;
                    top:0;
                    left:0;
                    width: 10px;
                    height: 1px;
                    background-color: #fff;
                    transform-origin: center right;
                  }

                }
                &.ui-icon-circle-triangle-w {
                  &:after {
                    transform-origin: center left;
                    transform: rotate(45deg);
                  }
                  &:before {
                    transform-origin: center left;
                    transform: rotate(-45deg);
                  }
                }
                &.ui-icon-circle-triangle-e {
                  &:after {
                    transform: rotate(-45deg);
                  }
                  &:before {
                    transform: rotate(45deg);
                  }
                }
              }
            }

            .ui-datepicker-calendar {
              thead {
                @include font-montserrat-medium();
                font-size: 17px;
              }
              tbody {
                @include font-montserrat-regular();
                font-size: 17px;
                tr {
                  position: relative;
                  &:after {
                    content: '';
                    position: absolute;
                    //top: -1px;
                    margin-top: -1px;
                    left: -1px;
                    width: calc(100% + 2px);
                    height: 47px;
                    border: 1px solid #707070;
                    opacity: 0;
                    pointer-events: none;
                    border-radius: 5px;
                  }
                  &.active-row,
                  &:hover {
                    &:after {
                      opacity: 1;
                      @include transition-all();
                    }
                  }
                }

                td {
                  text-align: center;
                  a, span {

                    color: #fff;
                    text-align: center;
                    margin:2px 0;

                    background-color: transparent;
                    border-radius: 50%;
                    border: 1px solid transparent;
                    width: 32px;
                    height: 32px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;



                    &.ui-state-hover {
                      //background-color: ;
                    }
                    &.ui-state-active { //current set date, different than today
                    //&.ui-state-highlight { //active today date
                      background-color: #3A3A3A;
                      border-radius: 50%;
                      //border: 1px solid #707070;
                      border: none;
                      width: 32px;
                      height: 32px;
                      display: inline-flex;
                      text-align: center;
                      justify-content: center;
                      align-items: center;
                    }
                  }
                  &.ui-datepicker-other-month {
                    a {
                      color: #707070;
                    }
                  }
                  &.ui-state-disabled {
                    span {
                      background-color: transparent;
                      border: none;
                    }
                  }
                }

              }

            }
          }

          .filter-row {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            @media only screen and (max-width: $breakpoint-small-medium) {
              justify-content: flex-start;
              margin-top: 20px;
              flex-wrap: wrap;
            }

            .btn-outer {
              margin: 0 20px 10px 0;

              &.filter-hook-special-event-check-special-event {
                display: none; // show this via JS
              }

              &:last-child {
                margin-right: 0;
              }
            }

            margin-bottom: 20px;

            &:last-child {
              margin-bottom: 0;
            }

            .columns {
              .column {
                margin: 0 0 10px;

                &:last-child {
                  margin-bottom: 0;
                }

                display: flex;
                align-items: center;

                button {
                  margin-right: 15px;
                  background-color: transparent;
                  padding: 0;
                  border: none;
                  cursor: pointer;
                  line-height: 0;

                  svg {
                    width: 20px;

                    path {
                      fill: #fff;
                    }

                    .icon-checkbox-check {
                      opacity: 0;
                      @include transition-all();
                    }
                  }

                  &:hover {
                    svg {
                      .icon-checkbox-check {
                        opacity: 1;
                      }
                    }
                  }

                  &.active {
                    svg {
                      .icon-checkbox-check {
                        opacity: 1;
                      }
                    }
                  }
                }

                .checkbox-label {
                  @include font-montserrat-semibold();
                  font-size: 14px;
                  color: #fff;
                }
              }
            }

            //columns


          }
        }

        .listing-filters {
          .filter-row {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            @media only screen and (max-width: $breakpoint-small-medium) {
              justify-content: flex-start;
              margin-top: 20px;
              flex-wrap: wrap;
            }

            .lbl {
              @include font-montserrat-semibold();
              color: #707070;
              font-size: 14px;
              margin-right: 20px;
              margin-bottom: 10px;
              @media only screen and (max-width: $breakpoint-small-medium) {
                width: 100%;
                display: block;
              }
            }

            .btn-outer {
              margin: 0 20px 10px 0;

              &.filter-hook-special-event-check-special-event {
                display: none; // show this via JS
              }

              &:last-child {
                margin-right: 0;
              }
            }

            margin-bottom: 20px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.no-matches {
  @include master-margin-tb();
  display: none;
}
.grid {
  @include master-margin-tb();
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 40px;
  row-gap: 40px;
  @media only screen and (max-width: $breakpoint-medium-large) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media only screen and (max-width: $breakpoint-medium) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media only screen and (max-width: $breakpoint-small) {
    grid-template-columns: repeat(1, 1fr);
    body.page-cast-recordings  & {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media only screen and (max-width: $breakpoint-small) {
    body.page-cast-recordings  & {
      column-gap: 20px;
      row-gap: 20px;
    }
  }


  .item {
    .img {
      width: 100%;
      aspect-ratio: 1;

      a {
        width: 100%;
        height: 100%;
        display: block;
      }

      .bgimg {
        border-radius: 9px;
      }
    }

    .info {
      margin-top: 20px;

      h4 {
        @include font-montserrat-semibold();
        line-height: 1.5em;
        color: #fff;
        @include transition-all();
        margin-bottom: 5px;

        &:hover {
          color: $color-pink;
        }
      }

      .icon-copy {
        margin: 2px 0;
        &.block {
          display: block;
        }

        .tour-linkout-row {
          display: block;
          width:100%;
          border-top: 0;
          padding-top: 0;
          margin-top: 0;
          a {
            color:$color-pink;
            &:hover {
              color: #fff;
            }
          }
          .col1 {
            width:100%;
          }
        }

        * {
          color: $color-gray;
          font-size: 14px;
          @include transition-all();
        }

        .icon {
          svg {
            position: relative;
            //top:2px;
            path {
              fill: $color-gray;
              @include transition-all();
            }
          }

          &.star {
            svg {
              top: 0;
            }
          }
        }

        &.special-show-filter-hook {
          cursor: pointer;

          * {
            color: $color-pink;
          }

          .icon {
            svg {
              path {
                fill: $color-pink;
              }
            }
          }

          &:hover {
            * {
              color: #fff;
            }

            .icon {
              svg {
                path {
                  fill: #fff;
                }
              }
            }
          }
        }
      }
    }

    @media only screen and (max-width: $breakpoint-small) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .img {
        @include calc(width, '50% - 20px');
        margin-right: 20px;
      }
      .info {
        margin-top: 0;
        width: 50%;
      }

      @media only screen and (max-width: $breakpoint-tiny) {
        .img {
          @include calc(width, '30% - 20px');
        }
        .info {
          width: 70%;
        }
      }


      body.page-cast-recordings  & {
        display: block;
        .img, .info {
          width: 100% !important;
        }
        .info {
          margin-top: 10px!important;
        }
      }


    }
  }

}

body.page-international {
  .top {
    .lr {
      .r {
        display: none !important;
      }
    }
  }

  .filters {
    @include master-margin-tb();
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .lbl {
      margin: 0 20px;
      @include font-montserrat-bold();
      font-size: 14px;
      color: #707070;
    }

    .btn-outer {
      margin-right: 20px;

      .btn {
        text-transform: unset;
        background-color: $color-gray-dark;
        color: #fff;
        &:hover {
          background-color: #fff;
          color: $color-gray-dark;
        }
      }
    }
  }
}



.closeable-chips {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  .btn-outer {
    margin-right: 15px;
    margin-bottom: 15px;
    &:last-child {
      margin-right: 0;
    }
    .btn {
      align-items: center;
      justify-content: center;
      &.dark {
        background-color: #000;
        color: #707070;
      }
      display: flex;
      .close {
        margin-left: 10px;
        text-transform: uppercase;
        top:1px;
        position: relative;
        svg {
          width: 9px;
          height: auto;
          path {
            @include transition-all();
            fill: #707070;
          }
        }
      }
      &:hover {
        &.dark {
          color: #fff;
        }
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
    span {
      text-transform: none;
    }
  }
}
