#modalPopupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  visibility: hidden;

  #modalPopupOverlayScrim {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: block;
  }

  #modalPopupOverlayInner  {
    @include calc(width, '100% - 200px');
    margin: 150px auto;
    max-width: 530px;
    border: 1px solid #535353;
    border-radius: 5px;
    background-color: #000;
    @media only screen and (max-width: $breakpoint-small) {
      @include calc(width, '100% - 40px');
      margin: 50px 20px 0;
    }
    .overlay-x {
      position: absolute;
      z-index: 20;
      right: 0;
      top:-35px;
    }
    .inner2 {
      width: auto;
      padding:30px;
      h1, h2, h3, h4, h5 {
        text-transform: uppercase;
        margin-bottom: 30px;
      }
      h6 {
        margin-bottom: 5px;
        @include font-montserrat-semibold();
      }
      p, a, h6 {
        font-size: 18px;
      }
    }
  }



}
