@mixin font-montserrat-thin() {
  font-family: 'Montserrat', sans-serif;
  font-weight: 100;
}
@mixin font-montserrat-thin() {
  font-family: 'Montserrat', sans-serif;
  font-weight: 100;
}
@mixin font-montserrat-light() {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
}
@mixin font-montserrat-regular() {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

@mixin font-montserrat-medium() {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

@mixin font-montserrat-semibold() {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

@mixin font-montserrat-bold() {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}
