#quick-one-captions {
  display: none; /* this is used in content blocks and specifically styled there */
}
.quick-one-swipe {
  width: 100%;
  height:100%;

  .quick-one-swipe-outer, .swiper-wrapper, .swiper-slide, .quick-one-swipe-swiper, .bgimg-outer {
    width: 100%;
    height:100%;
  }
  .swiper-slide {
    .bgimg-outer {
      position: relative;
    }
    .caption {
      position: absolute;
      left:15px;
      bottom:15px;
      width:80%;
      font-size: 12px;
      @include font-montserrat-medium();
      color:#fff;
      @include pop-out-text-subtle();
      @media only screen and (max-width: $breakpoint-small) {
        display: none;
      }
    }
    .play {
      position: absolute;
      top:calc(50% - 21px);
      left:calc(50% - 21px);
      width:52px;
      height: 52px;
      border-radius: 50%;
      background-color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .play2 {
        svg {
          width: 22px;
          height: 18px;
          left: 2px;
          top:1px;
          position: relative;
          path {
            fill: $color-pink;
            @include transition-all();
          }
        }
      }
      &:hover {
        .play2 {
          svg {
            path {
              fill: $color-red;
            }
          }
        }
      }
    }
    .icon {
      position: absolute;
      right:15px;
      top:15px;
      z-index: 3;
      svg {
        width: 20px;
        height: auto;
        path {
          fill: #fff;
          @include transition-all();
          opacity: 0.5;
          cursor: pointer;
        }
      }
      &:hover {
        svg {
          path {
            opacity: 1;
          }
        }
      }
    }
  }
  .side {
    position: absolute;
    //top:calc(50% - 26px);
    top:50%;
    @media only screen and (max-width: $breakpoint-small) {
      display: none;
    }
    &.prev {
      right: unset;
      left:100px;
    }
    &.next {
      left: unset;
      right:100px;

    }
  }

  #quick-one-dots {
    z-index: 2;
    width: calc(100% - 80px);
    text-align: center;
    position: absolute;
    top:12px;
    left:40px;
    display: none;
    @media only screen and (max-width: $breakpoint-small) {
      display: block;
    }

    .dots {
      top:0!important;
      bottom: unset!important;
      .swiper-pagination-bullet  {
        background-color: rgba(255, 255, 255, 0.7);
        &.swiper-pagination-bullet-active {
          background-color: rgba(255, 255, 255, 1);
        }
      }
      &.swiper-pagination-disabled {
        display: none;
      }
    }
  } //quick-thumbs-dots

}