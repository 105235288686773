.prefooter {
  background-color: $color-light;
  .footer-home {
    background-color: $color-light;
    @include master-padding-tb();
    .inner {
      margin:20px 0 0;
    }
    .panel {
      @include master-padding-lr-width-2x();

      .lr {
        display: flex;
        justify-content: space-between;
        .l {
          width: 100%;
        }
        .r {
          @include widthMaxMin(300px);
          margin-left: 80px;
          .social-icons .icon a {
            svg path {
              fill: $color-pink;
            }
            &:hover {
              svg path {
                fill: $color-red;
              }
            }
          }
        }
        h5 {
          text-transform: uppercase;
        }

        @media only screen and (max-width: $breakpoint-small) {
          display: block;
          .r {
            @include widthMaxMin(100%);
            margin:40px 0 0 0;
          }
        }
      }


    }
  }

  .footer-promotions {
    background-color: $color-light;
    @include master-padding-tb();
    .inner {
      margin:20px 0 0;
    }
    .panel {
      @include master-padding-lr-width-2x();
      @media only screen and (max-width: $breakpoint-small) {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
        .quick-thumbs{
          width: 100%;
          @media only screen and (max-width: $breakpoint-quickthumbs) {
            @include master-padding-lr-width-2x();
          }
        }
        .quick-thumbs-dots-outer {
          width: 100%;
          margin-top: 30px;
          margin-left: 0;
          @media only screen and (max-width: $breakpoint-quickthumbs) {
            width:80%;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
  }
  .footer-sponsors {
    background-color: $color-light;
    @include master-padding-tb();
    .inner {
      margin:40px 0 50px;
      @include master-padding-lr-width-2x();
    }
    .panels {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .panel {
        width: calc(25% - 30px);
        margin: 30px 0;
        @media only screen and (max-width: $breakpoint-small) {
          width: calc(50% - 20px);
          margin: 20px 0;
        }

        aspect-ratio: 1;
        background-color: #fff;
        border:1px solid #DBDBDB;
        border-radius: 15px;
        a {
          width: 100%;
          height: 100%;
          display: block;
        }
        .bgimg {
          background-size: contain;
          width: 96%;
          height: 96%;
          left: 3%;
          top: 3%;
        }
      } //panel

      &.smaller-quantity {
        justify-content: center;
        @media only screen and (max-width: $breakpoint-small) {
          justify-content: space-between;
        }
        .panel {
          margin-left: 40px;
          margin-right: 40px;
          @media only screen and (max-width: $breakpoint-small) {
            margin-left: 0;
            margin-right: 0;
          }
        }

      }

    } //panels
  }

  &.side {
    .capper-padded {
      padding-left: 0!important;
      padding-right: 0!important;
      width: 100% !important;
    }
    .footer-promotions {
      .panel {
        padding-left: 0!important;
        padding-right: 0!important;
        width: 100% !important;
      }
    }
    .footer-sponsors {
      .inner {
        padding-left: 0!important;
        padding-right: 0!important;
        width: 100% !important;
        .panels {
          .panel {
            width: calc(50% - 15px);
            margin: 15px 0;
          }
        }
      }
    }
  }
}