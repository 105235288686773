#featured-gallery {
  overflow: hidden;
  .items {
    width:100%;
    .swiper-wrapper, .swiper-slide {
      width:100%;
    }
    .swiper-slide {
      cursor: pointer;
    }
  }

  .swiper-button-next {
    right:65px;
    @media only screen and (max-width: $breakpoint-small) {
      right:20px;
      display: none;//actually, hide these
    }
  }
  .swiper-button-prev {
    left:65px;
    @media only screen and (max-width: $breakpoint-small) {
      left: 20px;
      display: none;//actually, hide these
    }
  }

  .gallery-big {
    width:100%;
    opacity: 0;
    aspect-ratio: 1920 / 615;
    @media only screen and (max-width: $breakpoint-small) {
      aspect-ratio: 1920 / 1100;
    }
    a {
      display: block;
      width: 100%;
      height: 100%;
    }
    .scrim {
      opacity: 0;
      position: absolute;
      top:73%;
      left:0;
      width: 100%;
      height: 27%;
      background-image: linear-gradient(to bottom, rgba(25, 25, 25, 0), #191919);
      @include transition-opacity();
    }
  }

  .gallery-thumbs-outer {
    width: calc(100% - 160px);
    //margin-left: 80px;
    margin-left: 250px;
    margin-top: -50px;
    margin-bottom: 40px;
    @media only screen and (max-width: $breakpoint-small) {
      margin-left: 80px;
    }
    .swiper-slide {
      display: flex;
      align-items: center;
      background-color: transparent!important;
      a {
        width: 100%;
        height: 100%;
        display: block;
      }
      .border-hover {
        width: 100%;
        position: relative;
        height: auto;
        aspect-ratio: 1 / 1;
        box-shadow: 0 0 20px #000;
        background-color: rgba(0, 0, 0, 1);
        .bgimg {
          left:0;
          top:0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .gallery-thumbs {
    opacity: 0;
    width:100%;
    height:100%;
    @include transition-opacity();
    overflow: visible!important;
    .swiper-slide {
      cursor: pointer;
      background-color: $color-dark;
      width:200px;
    }

    //rollovers
    .swiper-slide {
      transform: scale(1);
      @include transition-all();
      &:hover {
        transform: scale(1.1);
        //margin-top: -80px;
        z-index: 11;
      }
    }
    .swiper-slide-active {
      width:250px!important;
      margin-top: -80px;
      @media only screen and (max-width: $breakpoint-small) {
        width:25vw!important;
        margin-top: -5vw;
      }
      @media only screen and (max-width: 400px) {
        margin-top: -4vw;
        width:30vw!important;
      }
      z-index: 10;
      .border-hover .bgimg {
        opacity: 1;
      }
      &:hover {
        transform: scale(1);
      }
    }
  }
}