
footer.footer {
  background-color: #000;
  //margin:40px 0 0;
  .footer2 {
    @include master-padding-tb();

    background-color: #000;
  }

  //////////////////////////////////////////////////
  .legal-lr {
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: $breakpoint-small) {
      flex-direction: column-reverse;
      flex-wrap: wrap;
    }
    .r {
      .mobile-main-logo {
        display: none;
        @media only screen and (max-width: $breakpoint-small) {
          display: block;
          width: 95%;
          margin-left: auto;
          margin-right: auto;
          max-width: 420px;
          img {
            width: 100%;
          }
        }
      }
      width: 275px;
      margin-top: 40px;
      @media only screen and (max-width: $breakpoint-small) {
        width: 100%;
        text-align: center;
      }

      img, p {
        @media only screen and (max-width: $breakpoint-small) {
          width: 80%;
          margin-left: auto;
          margin-right: auto;
        }
      }

      img {
        width: 100%;
        margin:20px 0;
        @media only screen and (max-width: $breakpoint-small) {
          max-width: 300px;
        }
      }
      p {
        @media only screen and (max-width: $breakpoint-small) {
          max-width: 420px;
        }
      }

      * {
        color: $color-gray;
        @include font-montserrat-medium();
        font-size: 16px;
      }
    }
    .l {
      width: calc(100% - 275px);
      @media only screen and (max-width: $breakpoint-small) {
        width: 100%;
      }
    }


    .rowline {
      margin:20px 0 40px;
      &.main-logo {
        img {
          width:275px;
        }
        @media only screen and (max-width: $breakpoint-small) {
          display: none;
        }
      }
      &.logos {
        margin:20px 0;
        h5 {
          @include font-montserrat-semibold();
          font-size: 16px;
          color:#555555;
          @media only screen and (max-width: $breakpoint-small) {
            text-align: center;
          }
        }
        .logo-holder {
          display: flex;
          flex-wrap: wrap;
          @media only screen and (max-width: $breakpoint-small) {
            justify-content: space-around;
          }
        }
        .logo {
          display: inline-block;
          min-width:85px;
          height: 85px;
          margin:10px 40px 10px 0;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          @media only screen and (max-width: $breakpoint-small) {
            margin:10px 20px 10px 20px;
          }
        }
      } //logos
      &.nav {
        margin-top:60px;
      }
      &.nav, &.legal {
        *  {
          font-size: 14px;
          @include font-montserrat-medium();
          color:#ADADAD;
        }
        a {
          @include transition-color();
          &:after {
            content:"|";
            margin-right: 10px;
            margin-left: 10px;
            color:#ADADAD!important;
          }
          &:last-child {
            &:after {
              display: none;
            }
          }
          &:hover {
            color:#fff;
            &:after {
              color:#ADADAD!important;
            }
          }
        }
      } //nav legal

      .r {
        .social-icons {
          margin:20px 0;
        }
      }
    }
  }

}

body.theme-dark {
  .footer-promotions {
    .rowline {
      display: none;
    }
  }
}