.combobox {
  width: 100%;
  background-color: #3A3A3A;
  border-radius:3px;
  border:1px solid #5D5D5D;
  .combobox-display {
    cursor: pointer;
    .combobox-display2 {
      padding:15px 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .lbl {
        color:#fff;
        @include font-montserrat-semibold();
        font-size: 16px;
      }
      .triangle {
        @include transition-all();
        svg {
          width: 10px;
          path {
            fill:#fff;
          }
        }

      }

    }
  }
  .combobox-choices {
    display: none;
    button {
      width: 100%;
      padding:10px 30px;
      border-radius: 0;
      border: none;
      background-color: transparent;
      text-align: left;
      cursor: pointer;
      @include transition-all();

      color:#fff;
      @include font-montserrat-semibold();
      font-size: 16px;
      &:hover {
        background-color: #000;
      }
    }
  }
  &.open {
    .combobox-choices {
      display: block;
    }
    .triangle {
      transform: rotate(-90deg);
    }
  }
}