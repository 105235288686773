// SIZES
$headerTall:175px;
$headerSmall:120px;

//COLORS
$color-light: #FCFCFC;
$color-gray: #797979;
$color-gray-dark: #3A3A3A;
$color-dark: #191919;

$color-pink:#DA1653;
$color-red: #9A0633;
$color-error: $color-red;


$color-teal:#3E7A9B;
$color-teal-dk: #1a6289;
$color-teal-dk2: #083856;
$color-blue:#257DF9;
$color-blue-dk:#010E3A;

.color-pink  { color:$color-pink;}
.color-red  {  color:$color-red;}

