.theater-access {
  background-color: #EFEFEF;
  @include master-padding-tb();
  .capper {
    @include master-padding-tb();
  }
  .lr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include master-padding-tb();
    //@include master-padding-lr-width-2x();
    @media only screen and (max-width: $breakpoint-small) {
      display: block;
    }
    .img {
      @include widthMaxMin(137px);
      margin-right: 40px;
      @media only screen and (max-width: $breakpoint-small) {
        margin:40px auto;
      }
      img {
        width: 100%;
      }
    }
    .theater-access-copy {
      width: 100%;
      .rte * {
        font-size: 18px;
        @include font-montserrat-medium();
        line-height: 2em;
      }
    }
  }
}