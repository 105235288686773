body.page-home {
  background-color: $color-dark;
  #featured-gallery {
    z-index: 2;
  }
  .mobile-logline {
    display: none;
    @media only screen and (max-width: $breakpoint-small) {
      display: block;
      * {
        @include font-montserrat-medium();
        font-size: 16px;
        color: #797979;
        line-height: 1.4em;
      }
      background-color: $color-dark;
      .mobile-logline2 {
        @include master-padding-tb();
      }
    }
  }
  .bottom {
    width: 100%;
    overflow: visible;
    .bottom-bg {
      top:-100px;
      width: 100%;
      height: auto;
      aspect-ratio: 2;
    }


    .panel {
      @include master-margin-tb();
      width:100%;
      //@include master-padding-tb();
      padding-top:70px;
      padding-bottom: 30px;
      border-top: 1px solid #3E3E3E;
      @media only screen and (max-width: $breakpoint-small) {
        padding-top: 30px;
        margin-top: 0!important;
      }
      &:last-child {
        //border-bottom: none;
      }
      .lr {
        width:100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .panel-titles {
          .side {
            display: none;
            @media only screen and (max-width: $breakpoint-small) {
              display: block;
              top:-5px;
            }
          }
          @media only screen and (max-width: $breakpoint-small) {
            display: flex;
            align-items: center;
            .swiper-button-prev {
              left:15px
            }
            .swiper-button-next {
              right:15px
            }
            .c {
              width: 100%;
            }
          }
        }
        .l {
          @include widthMaxMin(180px)
        }
        .r {
          @include calc(width, '100% - 120px');
        }
        @media only screen and (max-width: $breakpoint-small) {
          flex-wrap: wrap;
          .l {
            @include widthMaxMin(100%);
            text-align:center;
          }
          .r {
            @include calc(width, '100%');
            @media only screen and (max-width: $breakpoint-small) {
              margin-top: 40px;
            }
          }
        }
      }
      h1 {
        white-space: pre-line;
        word-spacing: 180px;
        margin-bottom:0;
        @media only screen and (max-width: $breakpoint-small) {
          word-spacing: 0;
        }
      }
      h2 {
        @include font-montserrat-bold();
        font-size: 18px;
        color: $color-pink;
        text-transform: uppercase;
        @media only screen and (max-width: $breakpoint-small) {
          margin-bottom: 0;
        }
      }
      .btn-outer {
        margin-top: 20px;
        @media only screen and (max-width: $breakpoint-small) {
          text-align: center;
          width: 100%;
        }
      }
    }
  }
}
