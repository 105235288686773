body.page-contact {
  .contact-body {
    margin-top: 40px;
  }
  .formie {
    margin-top: 40px;
    textarea {
      min-height: 230px;
    }
    .small-below-form {
      margin-top: 30px;
      font-size: 13px;
      * {
        font-size: 13px;
        line-height: 1.5em;
      }
    }
    .fui-btn {
      @extend .btn;
    }
  }
}
