.quick-thumbs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media only screen and (max-width: $breakpoint-small) {
    @include calc(width,'100% - 160px');
    margin-left: auto;
    margin-right: auto;
    &.shows, &.tours {
      @include calc(width,'100%');
    }
  }
  @media only screen and (max-width: $breakpoint-quickthumbs) {
    width: 100%;
  }

  .swiper-wrapper, .swiper-slide {
    width:100%;
  }
  .swiper-slide {
    cursor: pointer;
  }

  .side {
    @media only screen and (max-width: $breakpoint-quickthumbs) {
      display: none;
    }

    position: relative;
    @include widthMaxMin(80px);
    .swiper-button-prev {
      left:0;
      right: unset;
    }
    .swiper-button-next {
      left:23px;
      right: unset;
    }
  }

  &.shows, &.tours {
    @media only screen and (max-width: $breakpoint-small) {
      .swiper-button-prev,
      .swiper-button-next{
        top:-100px;
      }

      .swiper-grid-column>.swiper-wrapper {
        flex-direction: unset;
      }
    }
    .bgimg {
      opacity: 1!important;
    }
    .side {
      @media only screen and (max-width: $breakpoint-small) {
        display: none !important;
      }
    }
  }

  .quick-thumbs-outer {
    width: calc(100% - 160px);
    margin-left: 80px;
    display: flex;
    @media only screen and (max-width: $breakpoint-small) {
      //body.page-home & {
        width: 100% !important;
        margin-left: 0!important;
      //}
    }
    .swiper-slide {
      display: flex;
      align-items: center;
      a {
        display: block;
        width: 100%;
        height: 100%;
      }
      .bgimg {
        position: relative;
        height: auto;
        aspect-ratio: 1 / 1;
      }
    }
  }
  .quick-thumbs-swiper {
    width:100%;
    height:100%;
    .swiper-slide {
      cursor: pointer;
      //background-color: $color-dark;
      border-radius: 15px;
      .bgimg {
        opacity:0.4;
        border-radius: 15px;
        @include transition-opacity();
      }
      &:hover {
        .bgimg {
          opacity: 1;
        }
      }
    }
    .swiper-slide-active {
      .bgimg {
        opacity:1;
      }
    }
  }

  &.promos {
    .quick-thumbs-outer {
      margin-left: -80px;
      width: calc(100% + 160px);
      @media only screen and (max-width: $breakpoint-quickthumbs) {
        width: 100%;
        margin-left: 0;
      }
    }
    .quick-thumbs-swiper {
      .swiper-slide {
        .bgimg {
          opacity: 1;
        }
        &:hover {
          .bgimg {
            opacity: 0.85;
          }
        }
      }
    }
  }

  &.promos.side {
    .side {
      display: none;
    }
    .quick-thumbs-outer {
      width: 100%;
      margin-left: 0;
    }
  }
} //quick-thumbs


.quick-thumbs-dots-outer {
  margin-top: 20px;
  width: 90%;
  margin-left: 5%;
  display: flex;
  align-items: center;
  $tw: 30px;
  .side {
    width: $tw;
    .swiper-button-next, .swiper-button-prev {
      width: $tw;
      height: $tw;
      left:0;
    }
  }
  .quick-thumbs-dots {
    width: calc(100% - $tw - $tw);
    text-align: center;
    .dots {
      .swiper-pagination-bullet  {
        background-color: rgba(25, 25, 25, 0.7);
        &.swiper-pagination-bullet-active {
          background-color: rgba(25, 25, 25, 1);
        }
      }
      &.swiper-pagination-disabled {
        display: none;
      }
    }
    &.dark-dots {
      .dots {
        .swiper-pagination-bullet  {
          background-color: rgba(255, 255, 255, 0.7);
          &.swiper-pagination-bullet-active {
            background-color: rgba(255, 255, 255, 1);
          }
        }
      }
    }

  } //quick-thumbs-dots

  @media only screen and (min-width: $breakpoint-quickthumbs-plus-1) {
    display: none;
    &.promos.side {
      display: flex;
    }
  }
  &.shows, &.tours {
    display: none;
  }

} //quick-thumbs-dots outer
